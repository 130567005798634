import { Paper, Stack } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";

const PageNotFound = () => {
  const { pathname } = useLocation();
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      spacing={4}
      sx={{ height: "100vh" }}
    >
      <Paper sx={{ p: 2 }} elevation={3}>
        <span>Page not found</span>
        <span className="empty-details">{pathname}</span>
      </Paper>
    </Stack>
  );
};

export default PageNotFound;
