import React, { useEffect, memo } from "react";
import useTranslation from "components/customHooks/translations";

const withPageTitle = (title: string, Component: React.ComponentType) => () => {
    const translation = useTranslation();
    const Memoized = memo(Component);

    useEffect(() => {
        document.title = translation[
            title as keyof typeof translation
        ] as string;
    }, [translation]);
    return <Memoized />;
};

export default withPageTitle;
