import { useState } from "react";

export default function useTenant() {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    return {
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
    };
}
