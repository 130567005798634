import React, { useState, useContext } from "react";
import useLoading from "components/customHooks/useLoading";

const LoadingContext = React.createContext();
export const useLoadingContext = () => useContext(LoadingContext);

export default function LoadingContextProvider({ children }) {
    const { loading, setLoading } =
        useLoading();


    return (
        <LoadingContext.Provider
            value={{
                loading, setLoading
            }}
        >
            {children}
        </LoadingContext.Provider>
    );
}
