import React from "react";
import { Typography } from "@mui/material";

const PageHeader = ({ children }: { children: React.ReactNode }) => {
    return (
        <Typography variant="h4" sx={{ marginBottom: 4 }}>
            {children}
        </Typography>
    );
};

export default PageHeader;
