import { useState } from "react";

export default function useTenant() {
    const [loading, setLoading] = useState(false);


    return {
        loading,
        setLoading,
    };
}
